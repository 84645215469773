<template>
  <div class="page-event-calendar">
    <h3 class="margins1">Календарь событий</h3>
    <div class="page-event-calendar__filter heap heapR">
      <div class="flexR center">
        <div class="heap-block">
          <DateSelector label="Дата нач." :val="date_begin" @change="date_begin = $event" />
        </div>
        <div class="heap-block">
          <DateSelector label="Дата кон." :val="date_end" @change="date_end = $event" />
        </div>
      </div>
      <div class="heap-block">
        <div @click="tableDataRetrieve" class="high pointer">
          <button class="btn" ref="btn">Обновить</button>
        </div>
      </div>
    </div>

    <div v-if="loading">
      <Loader />
    </div>

    <transition name="fade">
      <div v-if="!loading" class="flexC">
        <template v-if="rowsCount">
          <div v-for="(mon, x) in monthList" :key="x" class="margins1">
            <h3>
              {{ titleFormat(mon.event_date, mon.event_year) }}
            </h3>
            <template v-for="(row, y) in report">
              <div
                v-if="mon.event_month === row.event_month && mon.event_year === row.event_year"
                :key="y"
                class="page-event-calendar__event flexR"
                style="margin-top: 0.5rem"
              >
                <div class="page-event-calendar__date nowrap">
                  {{ dateFormatHuman(row.event_date) }}
                </div>
                <div class="page-event-calendar__event-title">
                  {{ EVENT_TYPE[row.event_type] }} {{ row.event_name }}
                </div>
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <div>событий нет</div>
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import DateSelector from "@/components/DateSelector.vue";
import Loader from "@/components/Loader.vue";
import { request } from "@/components-js/requestSrv";
import { dateFormatHuman, dateFormatJS, pluralYears } from "@/components-js/dateFormat.js";
import { datesCheck } from "@/components-js/datesCheck.js";
import { EVENT_TYPE } from "@/config/settings.js";
import { lS } from "@/components-js/localStorage";
const loStorage = new lS();

export default {
  components: { Loader, DateSelector },
  data() {
    return {
      date_begin: null,
      date_end: null,
      loading: false,
      monthList: [],
      report: [],
      rowsCount: 0,
      EVENT_TYPE,
    };
  },

  mounted() {
    // set default values for dates and point
    this.date_begin = dateFormatJS(new Date());
    this.date_end = dateFormatJS(new Date(new Date().setDate(64)));

    let tmp = loStorage.getObjectProp(this.$route.path, "date_begin");
    if (tmp) this.date_begin = tmp;

    tmp = loStorage.getObjectProp(this.$route.path, "date_end");
    if (tmp) this.date_end = tmp;

    this.tableDataRetrieve();
  },

  methods: {
    titleFormat(date, year) {
      let month = new Intl.DateTimeFormat("ru-RU", { month: "long" }).format(new Date(date));
      month = month[0].toUpperCase() + month.substr(1);
      return month + " " + year;
    },

    dateFormatHuman(d) {
      return dateFormatHuman(d);
    },

    async tableDataRetrieve() {
      this.loading = true;
      this.report = [];
      this.monthList = [];

      const res = datesCheck(this.date_begin, this.date_end);
      this.date_begin = dateFormatJS(res.date_begin);
      this.date_end = dateFormatJS(res.date_end);

      let data = await request(
        "/api/report/eventbirths",
        "POST",
        { date_begin: this.date_begin, date_end: this.date_end },
        this.$route.path
      );

      let dataTmp = await request(
        "/api/report/eventanniversaries",
        "POST",
        { date_begin: this.date_begin, date_end: this.date_end },
        this.$route.path
      );

      data = data.concat(dataTmp);

      // year counter
      // let days = 0;
      // fill report array with ALL days and months in period
      for (
        let d = new Date(this.date_begin).getTime();
        d <= new Date(this.date_end).getTime();
        d += 1000 * 60 * 60 * 24
      ) {
        // if interval more, than year, stop filter
        // if (days++ > 365) break;
        // compare with each member of data
        for (const m of data)
          if (
            parseInt(m.event_day) === new Date(d).getDate() &&
            parseInt(m.event_month) === new Date(d).getMonth() + 1 &&
            parseInt(m.event_year) < new Date(d).getFullYear()
          ) {
            this.report.push({});
            Object.assign(this.report[this.report.length - 1], m);
            this.report[this.report.length - 1].event_name +=
              " " +
              parseInt((d - new Date(m.event_date).getTime()) / 1000 / 60 / 60 / 24 / 365) +
              " " +
              pluralYears(
                parseInt((d - new Date(m.event_date).getTime()) / 1000 / 60 / 60 / 24 / 365)
              );
            this.report[this.report.length - 1].event_year = new Date(d).getFullYear();
          }
      }

      // fill month array with month/date/year
      let monthCurrent = 0;
      let yearCurrent = 0;
      for (const e of this.report) {
        if (e.event_month === monthCurrent && e.event_year === yearCurrent) continue;
        monthCurrent = e.event_month;
        yearCurrent = e.event_year;
        this.monthList.push({});
        this.monthList[this.monthList.length - 1] = {
          event_month: e.event_month,
          event_date: e.event_date,
          event_year: e.event_year,
        };
      }

      // console.log("report", this.report);
      // console.log("monthList", this.monthList);

      this.rowsCount = this.report.length;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-event-calendar {
  &__date {
    width: 15%;
  }
  &__event-title {
    width: 85%;
  }
  @media (max-width: 1300px) {
    &__filter {
      flex-direction: column;
    }
    &__event {
      font-size: 16px;
    }
    &__date {
      width: 20%;
    }
    &__event-title {
      width: 80%;
    }
    h3 {
      font-size: 18px;
    }
  }
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 1s;
}
</style>
